import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import Banner from '../components/batchTx/banner';
import SaveTimeFeatures from '../components/batchTx/saveTimeFeatures';
import SaveFeeFeatures from '../components/batchTx/saveFeeFeatures';
import BatchLayout from '../components/batchTx/layout';
import SEO from '../components/seo';
import FirstSubscribe from '../components/batchTx/firstSubscribe';
import SecondSubscribe from '../components/batchTx/secondSubscribe';
import Blocto from '../components/batchTx/blocto';
import Spotlight from '../components/batchTx/spotlight';
import BatchTxContext from '../context/batchTx/index';

const FEAT_MAPS = {
  'save-time': <SaveTimeFeatures />,
  'save-fee': <SaveFeeFeatures />,
};

const SEO_MAPS = {
  'save-time': {
    title: 'Unlock Save Time with Batch TX | Blocto Wallet',
    description:
      'Experience the convenience of Blocto Batch TX. Mint NFTs, revoke authorizations, and merge transactions effortlessly. Subscribe for launch updates!',
    path: 'batch-tx/save-time',
    scriptType: 'text/javascript',
    script: `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "jug3vr3tcw");`,
  },
  'save-fee': {
    title: 'Unlock Save Fees with Batch TX | Blocto Wallet',
    description:
      "Explore how Blocto Wallet's Batch TX helps you save on gas fees. Enter your wallet address and see the difference over different time periods. Subscribe for launch updates!",
    path: 'batch-tx/save-fee',
    scriptType: 'text/javascript',
    script: `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "jufz5h8v18");`,
  },
};

const BatchTxPage = ({ pageContext }) => {
  const params = pageContext.routeParam in FEAT_MAPS ? pageContext.routeParam : 'save-fee';
  const [page, setPage] = useState(params);
  const [address, setAddress] = useState(null);

  const contextValue = useMemo(
    () => ({ page, setPage, address, setAddress }),
    [page, setPage, address, setAddress]
  );

  // setPage(params);

  return (
    <BatchTxContext.Provider value={contextValue}>
      <BatchLayout>
        <SEO {...SEO_MAPS[page]} />
        <Banner />
        {FEAT_MAPS[page]}
        <FirstSubscribe />
        <Blocto />
        <SecondSubscribe />
        <Spotlight />
      </BatchLayout>
    </BatchTxContext.Provider>
  );
};

BatchTxPage.propTypes = {
  pageContext: PropTypes.shape({
    routeParam: PropTypes.string,
  }),
};

BatchTxPage.defaultProps = {
  pageContext: {
    routeParam: 'save-fee',
  },
};

export default injectIntl(BatchTxPage);
