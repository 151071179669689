/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Image, Box, Text } from '@chakra-ui/react';
import defiMobileImg from '../../../../static/batch-tx/defi_mobile.png';
import defiDesktopImg from '../../../../static/batch-tx/defi_desktop.png';

const Defi = () => (
  <Box backgroundColor="#F9F9F9" borderRadius="12px">
    <Box padding="32px">
      <Text textAlign="center" color="#00325A" fontWeight="500" lineHeight="22px">
        Picture this: you have 100 USDC, and in a typical scenario, you'd need to approve, swap, and
        stake, each requiring a separate transaction.
      </Text>

      <Box display={{ md: 'none' }} mt="20px" mx="-6px">
        <Image src={defiMobileImg} />
      </Box>
      <Box display={{ base: 'none', md: 'block' }} mt="20px" maxWidth="456px">
        <Image src={defiDesktopImg} />
      </Box>
    </Box>
  </Box>
);

export default Defi;
