/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Grid, GridItem, Text, Box } from '@chakra-ui/react';
import 'swiper/css';
import 'swiper/css/pagination';
import tokenImg from '../../../static/batch-tx/token.png';
import clockImg from '../../../static/batch-tx/clock.png';
import mobileImg from '../../../static/batch-tx/mobile.png';
import bgImg from '../../../static/batch-tx/line.png';

const bloctoCardContent = [
  {
    getTitle: () => (
      <>
        Enhanced{' '}
        <Text as="span" color="font.highlight">
          gas
          <br />
          management
        </Text>
      </>
    ),
    content:
      'Get ready to experience a hassle-free paymaster experience! Effortlessly manage gas fees using ERC20 or our versatile gas-fee solution, Blocto Points, with the convenience of a credit card or, even better, without spending a single penny!',
    imgSrc: tokenImg,
  },
  {
    getTitle: () => (
      <>
        Web3 in under
        <br />
        <Text as="span" color="font.highlight">
          30 seconds
        </Text>
      </>
    ),
    content:
      'Onboard with just a few taps! Sign up with your email, easily log in with your social account, and say goodbye to complicated processes and the risk of misplacing your private keys.',
    imgSrc: clockImg,
  },
  {
    getTitle: () => (
      <>
        Take{' '}
        <Text as="span" color="font.highlight">
          Web3
          <br />
          on the go
        </Text>
      </>
    ),
    content:
      'With our intuitive mobile app, you can buy, sell, and trade digital assets right from your smartphone—no laptops, tablets, or browser extensions needed.',
    imgSrc: mobileImg,
  },
];

const Blocto = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '160px', lg: '200px' }}
    pb={{ base: '80px', md: '140px' }}
    backgroundImage={bgImg}
    backgroundRepeat="no-repeat"
    backgroundSize={{ base: 'auto', lg: 'unset' }}
    backgroundPosition={{ base: '40% 0', lg: 'center top' }}
  >
    <Box w="100%" maxW="1200px" m="0 auto">
      <Text
        color="font.highlight"
        fontSize={{ base: '40px', md: '49px' }}
        fontWeight="weight.m"
        lineHeight="55px"
        mb={{ base: '24px' }}
        maxWidth={{ base: '328px', md: '100%' }}
        textAlign={{ base: 'center', lg: 'left' }}
      >
        Who is Blocto?
      </Text>
      <Text
        fontSize="16px"
        lineHeight="26px"
        textAlign={{ base: 'center', lg: 'left' }}
        color="icon.text"
        mb={{ base: '40px' }}
      >
        The Smart Contract Wallet For Everyone. Enabled by Account Abstraction.
      </Text>

      <Box w="100%">
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap={{ base: 'space.s', md: 'space.xl', lg: 'space.m' }}
        >
          {bloctoCardContent.map((item) => (
            <GridItem width="100%" height="100%">
              <BloctoCard title={item.getTitle()} content={item.content} imgSrc={item.imgSrc} />
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  </Box>
);

const BloctoCard = ({ title, content, imgSrc }) => (
  <Box
    backgroundColor="white"
    boxShadow="0px 0px 20px rgba(35, 37, 40, 0.05)"
    borderRadius="32"
    position="relative"
    maxWidth={{ base: '388px' }}
    mx="auto"
    height={{ base: '360px', lg: '100%' }}
  >
    <Image
      src={imgSrc}
      position="absolute"
      top={{ base: '24px' }}
      right={{ base: '12px' }}
      maxWidth={{ base: '100px' }}
    />
    <Box padding="32px 32px">
      <Text
        color="icon.text"
        fontSize={{ base: '28px' }}
        fontWeight="500"
        pt="64px"
        lineHeight="30px"
      >
        {title}
      </Text>
      <Text color="#777A7D" fontWeight="400" lineHeight="22px" mt="12px">
        {content}
      </Text>
    </Box>
  </Box>
);

BloctoCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default Blocto;
