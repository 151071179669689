import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

/** the number of cart in the bus */
const CART_PER_BUS = Number(process.env.GATSBY_CART_PER_BUS) || 5;

const DAYS = {
  '1d': dayjs().subtract(1, 'day').unix(),
  '3d': dayjs().subtract(3, 'day').unix(),
  '7d': dayjs().subtract(7, 'day').unix(),
  '1m': dayjs().subtract(1, 'month').unix(),
};

/**
 * @param {number} txPerCart the number of tx in each cart, can use the user tx count
 * @param {number} totalUserTxGas the total gas of the user txs
 */
const estimateBatchTxGasUsed = (txPerCart, totalUserTxGas) => {
  const baseFee = BigNumber(21000).dividedBy(CART_PER_BUS);
  const callDataGas = BigNumber(840).times(txPerCart);
  // the gas we need to wrap the original tx
  const invoke2Overhead = BigNumber(1998.6).plus(BigNumber(2946.4).dividedBy(CART_PER_BUS));
  // the gas we can saved by using invoke2 & batch
  const invoke2SavedGas = BigNumber(-22529.3867).plus(BigNumber(10830.423).times(txPerCart));
  return BigNumber(totalUserTxGas).minus(
    invoke2SavedGas.minus(baseFee).minus(callDataGas).minus(invoke2Overhead)
  );
};

const getAddressTotalTxsNEthPrice = (address) =>
  fetch('https://us-central1-portto-frontend.cloudfunctions.net/ether-scan-batch-tx', {
    method: 'POST',
    body: JSON.stringify({ address }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((res) => res.json())
    .then((res) => res.data);

const getSumOfTxsTxFee = (txs = []) =>
  txs
    .reduce(
      (acc, tx) => BigNumber(acc).plus(BigNumber(tx.gasUsed).times(tx.gasPrice)),
      BigNumber(0)
    )
    .times(1e-18);

const getSumOfTxsTxFeeInUSD = (sumOfTxsTxFee, ethPrice) => BigNumber(sumOfTxsTxFee).times(ethPrice);

const getTxsBeforeDays = (txs, timestamp) => txs.filter((tx) => tx.timeStamp > timestamp);

const getProgressiveOfTxsGasUsed = (txs) =>
  txs.reduce((acc, tx) => {
    if (acc.length === 0) return [BigNumber(tx.gasUsed).toString()];
    return [
      ...acc,
      BigNumber(acc[acc.length - 1])
        .plus(BigNumber(tx.gasUsed))
        .toString(),
    ];
  }, []);

const getProgressiveOfBatchTxsGasUsed = (progressiveOfTxsGasUsed) =>
  progressiveOfTxsGasUsed.reduce(
    (acc, tx) => [
      ...acc,
      estimateBatchTxGasUsed(acc.length + 1, tx)
        .toFixed(0)
        .toString(),
    ],
    []
  );

const getBEVChartData = (txs) =>
  Object.entries(DAYS).reduce((acc, cur) => {
    const [days, timestamp] = cur;
    const txsBeforeDays = getTxsBeforeDays(txs, timestamp);
    if (txsBeforeDays.length === 0) return acc;

    const progressiveOfTxsGasUsed = getProgressiveOfTxsGasUsed(txsBeforeDays);
    const progressiveOfBatchTxsGasUsed = getProgressiveOfBatchTxsGasUsed(progressiveOfTxsGasUsed);

    return {
      ...acc,
      [days]: {
        label: Array.from({ length: progressiveOfTxsGasUsed.length }, (_, i) => i + 1),
        eoaData: progressiveOfTxsGasUsed,
        bevData: progressiveOfBatchTxsGasUsed,
      },
    };
  }, {});

const getSaveFeeResult = async (address) => {
  const lowerCaseAddress = address.toLowerCase();
  const { ethPrice, txs } = await getAddressTotalTxsNEthPrice(lowerCaseAddress);
  // indicate the past total transaction fees of the user spent
  const sumOfTxsTxFeeInEther = getSumOfTxsTxFee(txs).toString();
  // indicate the past total transaction fees of the user spent in USD
  const totalTxFeesInUSD = getSumOfTxsTxFeeInUSD(sumOfTxsTxFeeInEther, ethPrice)
    .toFixed(0)
    .toString();

  const bevChartData = getBEVChartData(txs);

  return {
    sumOfTxsTxFeeInEther,
    totalTxFeesInUSD,
    bevChartData,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { getSaveFeeResult };
