import React, { useContext } from 'react';
import { Flex, Text, Box, Image } from '@chakra-ui/react';
import bannerImg from '../../../static/batch-tx/banner_linear.png';
import bannerVideo from '../../../static/batch-tx/video.mp4';
import bannerVideoHome from '../../../static/batch-tx/video_home.jpg';
import { AnimationButton } from '../button/index';
import BatchTxContext from '../../context/batchTx/index';
import gtagEvent from '../../utils/gtag';

const GTAG_MAPS = {
  'save-fee': 'save_fee',
  'save-time': 'save_time',
};

const Banner = () => {
  const { page } = useContext(BatchTxContext);

  return (
    <Flex
      height={{ base: '507px', md: '1163px', lg: '831px', '2xl': '1008px' }}
      width="100%"
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '86px', md: '192px', lg: '112px' }}
      pb={{ base: '80px', md: '64px', lg: '0' }}
      bg="border.highlight"
      position="relative"
      _after={{
        display: 'block',
        content: '""',
        bg: 'border.highlight',
        width: '100%',
        height: { base: '80px' },
        position: 'absolute',
        bottom: { base: '-80px' },
        left: '0',
        zIndex: '-1',
      }}
    >
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'flex-start', lg: 'space-between' }}
        alignItems="center"
        width="100%"
        maxWidth="1200px"
        margin="0 auto"
      >
        <Box
          w={{ base: '100%', lg: '474px' }}
          pt={{ base: '95px', lg: 0 }}
          textAlign={{ base: 'center', lg: 'start' }}
        >
          <Text
            color="font.inverse"
            fontSize={{ base: '44px', md: '72px' }}
            fontWeight="weight.m"
            lineHeight={{ base: '120%', md: '110%' }}
          >
            Batch TX
          </Text>
          <Text
            color="font.inverse"
            fontSize={{ base: '16px', md: '20px' }}
            lineHeight={{ base: '26px', md: '28px' }}
            mt="space.xl"
            mb="space.xl"
          >
            Innovating with Batch TX for Savings, Efficiency, and Convenience in Every Transaction.
          </Text>
          <AnimationButton
            width="216px"
            height="44px"
            margin={{ base: 'auto', lg: 'unset' }}
            onClick={() => gtagEvent('batchtx_click_button', { page: GTAG_MAPS[page] })}
            as="a"
            href="#how"
          >
            Try Blocto Batch TX
          </AnimationButton>
        </Box>
        <Box
          display={{ base: 'none', md: 'block' }}
          height="490px"
          position="relative"
          order={{ md: -1, lg: 1 }}
        >
          <Image src={bannerImg} mb={{ md: '40px', lg: 0 }} pt="80px" />
          <Box
            width="234px"
            borderRadius="32"
            border="4px white solid"
            overflow="hidden"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="1"
          >
            <video loop autoPlay muted poster={bannerVideoHome}>
              <source src={bannerVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Banner;
