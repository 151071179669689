import React, { useState, useContext } from 'react';
import { Flex, Input, Text, Box, useToast } from '@chakra-ui/react';
import BatchTxContext from '../../context/batchTx';
import gtagEvent from '../../utils/gtag';
import ButtonDefault from '../button';

const isValidEmail = (value) => /\S+@\S+\.\S+/.test(value);

const MAILCHIMP_MAPS = {
  'save-time': '51b3ed014e',
  'save-fee': 'c3ca167d44',
};

const GTAG_MAPS = {
  'save-fee': 'save_fee',
  'save-time': 'save_time',
};

const SecondSubscribe = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { page, address } = useContext(BatchTxContext);

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is not validate');
      setEmail(event.target.value);
      return;
    }
    setError(null);
    setEmail(event.target.value);
    gtagEvent('batchtx_fillout_email', {
      page: GTAG_MAPS[page],
      page_section: 2,
      email: event.target.value,
      ...(address && { wallet_address: address }),
    });
  };

  const mailchimpApi = () => {
    const isSubscribed = localStorage.getItem(`isSubscribed-${page}`);

    if (isSubscribed || !isValidEmail(email)) return;
    const url = 'https://mailchimp-subscribe-kx654nxi2q-uc.a.run.app';
    const data = {
      email_address: email,
      list_id: MAILCHIMP_MAPS[page],
    };

    setLoading(true);
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          toast({
            title: 'Thank you for subscribing',
            variant: 'subtle',
            position: 'top',
            isClosable: true,
          });
          localStorage.setItem(`isSubscribed-${page}`, true);
        } else {
          toast({
            title: res.message,
            variant: 'subtle',
            status: 'error',
            position: 'top',
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        toast({
          title: err,
          variant: 'subtle',
          status: 'error',
          position: 'top',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      px={{ base: '24px', md: '72px' }}
      py="80px"
      bg="boGlobalColor.bluegray.200"
      borderRadius={{ base: '30px', md: '60px' }}
    >
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'flex-start' }}
        flexWrap="wrap"
        alignItems="center"
        width="100%"
        maxWidth="630px"
        margin="0 auto"
      >
        <Text
          width="100%"
          textAlign={{ base: 'center', md: 'unset' }}
          color="font.highlight"
          fontSize={{ base: '24px', md: '28px' }}
          fontWeight="500"
        >
          Keep up to date with our new feature launches!
        </Text>
        <Text
          width="100%"
          textAlign={{ base: 'center', md: 'unset' }}
          color="icon.text"
          fontSize="16"
          pt="12px"
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Don't worry, we hate spam too.
        </Text>
        <Flex flexDir={{ base: 'column', md: 'row' }} width="100%" gap="16px" mt="16px">
          <Box flex="1">
            <Input
              height="52px"
              p="16px 20px 16px 20px"
              bg="background.primary"
              border="none"
              borderRadius="140px"
              placeholder="Your email"
              _placeholder={{ color: 'font.secondary' }}
              type="email"
              onChange={handleChange}
            />
            {error && (
              <Text mt="10px" align="start" color="font.alert" w="100%">
                {error}
              </Text>
            )}
          </Box>
          <ButtonDefault
            width="168px"
            height="unset"
            fontSize="size.body.2"
            fontWeight="500"
            padding={{ base: '16px', md: '26px' }}
            textAlign="center"
            mx="auto"
            isLoading={loading}
            onClick={() => {
              mailchimpApi();
              gtagEvent('batchtx_submit_email', {
                page: GTAG_MAPS[page],
                page_section: 2,
                email,
                ...(address && { wallet_address: address }),
              });
            }}
          >
            Get early access
          </ButtonDefault>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SecondSubscribe;
