import { createContext } from 'react';

const BatchTxContext = createContext({
  page: null,
  setPage: () => {},
  address: null,
  setAddress: () => {},
});

export default BatchTxContext;
