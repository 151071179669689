/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, useStyleConfig } from '@chakra-ui/react';
import AOS from 'aos';

import Header from './header';
import Footer from './footer';
import useAmplitude from '../../../hooks/useAmplitude';
import '../../../../static/styles/main.scss';
import 'aos/dist/aos.css'; // You can also use <link> for styles

const Layout = ({ needBaseLayout, children }) => {
  const layoutContainerStyles = useStyleConfig('LayoutContainer');
  const amplitudeInstance = useAmplitude();

  useEffect(() => {
    AOS.init({ once: true });
    if (!amplitudeInstance) {
      console.error('amplitude is not initialized');
    }
  }, []);

  return (
    <>
      <Header />
      <Box as="main" __css={needBaseLayout ? layoutContainerStyles : null}>
        {children}
      </Box>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  needBaseLayout: PropTypes.bool,
};
Layout.defaultProps = {
  needBaseLayout: false,
};

export default Layout;
