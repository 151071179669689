import React from 'react';
import { Image, Box, Text } from '@chakra-ui/react';
import securityMobileImg from '../../../../static/batch-tx/security_mobile.png';
import securityDesktopImg from '../../../../static/batch-tx/security_desktop.png';

const Security = () => (
  <Box backgroundColor="#F9F9F9" borderRadius="12px">
    <Box padding="32px">
      <Text textAlign="center" color="#00325A" fontWeight="500" lineHeight="22px">
        Imagine this: you used the revoke cash dApp to grant permissions. Traditionally, revoking
        each one meant lots of separate transactions.
      </Text>

      <Box display={{ md: 'none' }} mt="20px" mx="-6px">
        <Image src={securityMobileImg} />
      </Box>
      <Box display={{ base: 'none', md: 'block' }} mt="20px" maxWidth="456px">
        <Image src={securityDesktopImg} />
      </Box>
    </Box>
  </Box>
);

export default Security;
