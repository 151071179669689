import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Link, Box, Text } from '@chakra-ui/react';

const MintSuccess = ({ txHash }) => (
  <Box backgroundColor="#F9F9F9" borderRadius="12px" height={{ base: '690px', md: '692px' }}>
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="32px"
      textAlign="center"
      gap="20px"
      h="100%"
    >
      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54ZM18.3893 26.6859C17.4702 25.8495 16.0471 25.9166 15.2108 26.8357C14.3745 27.7548 14.4415 29.1778 15.3606 30.0141L21.9742 36.0324C23.3285 37.2648 25.4186 37.1924 26.6844 35.8692L39.8759 22.0799C40.7349 21.182 40.7033 19.7577 39.8054 18.8987C38.9075 18.0397 37.4832 18.0713 36.6242 18.9692L24.1913 31.9656L18.3893 26.6859Z"
          fill="#0075FF"
        />
      </svg>
      <Text color="#00325A" fontSize="24px" fontWeight="500">
        Successful
      </Text>
      <Text color="#777A7D" fontSize={14} fontFamily="Work Sans" fontWeight="500" lineHeight="22px">
        Congratulations! You've Successfully Minted All 4 Unique NFTs
      </Text>
      <Link
        color="font.highlight"
        isExternal
        fontSize="14px"
        fontWeight="500"
        textDecoration="underline"
        href={`https://polygonscan.com/tx/${txHash}`}
      >
        View on Polygonscan
      </Link>
    </Flex>
  </Box>
);

MintSuccess.propTypes = {
  txHash: PropTypes.string.isRequired,
};

export default MintSuccess;
