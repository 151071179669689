import React from 'react';
import PropTypes from 'prop-types';
import { Image, Box, Text, Grid, GridItem, useToast } from '@chakra-ui/react';
import save1Img from '../../../../static/batch-tx/save_1.jpeg';
import save2Img from '../../../../static/batch-tx/save_2.jpeg';
import save3Img from '../../../../static/batch-tx/save_3.jpeg';
import save4Img from '../../../../static/batch-tx/save_4.jpeg';
import ButtonDefault from '../../button';
import bloctoSDK from '../../../utils/batchTx/provider';
import gtagEvent from '../../../utils/gtag';

const nfts = [
  { imgSrc: save1Img, url: 'https://opensea.io/collection/batchtx-for-timeease-1/overview' },
  { imgSrc: save2Img, url: 'https://opensea.io/collection/batchtx-for-timeease-2/overview' },
  { imgSrc: save3Img, url: 'https://opensea.io/collection/batchtx-for-timeease-3/overview' },
  { imgSrc: save4Img, url: 'https://opensea.io/collection/batchtx-for-timeease-4/overview' },
];

const page = 'save_time';

const isUserRejectedRequestError = (error) => /(user rejected)/i.test(error.message);

const NFT = ({ handleOneClickMinted }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const toast = useToast();

  const handleOneClickMint = async () => {
    setIsLoading(true);
    try {
      // 1. request account
      const [account] = await bloctoSDK.ethereum.request({ method: 'eth_requestAccounts' });

      // 2. send mint nft batch tx
      const txHash = await bloctoSDK.ethereum.request({
        method: 'blocto_sendBatchTransaction',
        params: [
          {
            from: account,
            to: '0x00005EA00Ac477B1030CE78506496e8C2dE24bf5',
            data: '0x161ac21f0000000000000000000000000692b063d018962d727fe04ee5198f2c1a44958e0000000000000000000000000000a26b00c1f0df003000390027140000faa71900000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001360c6ebe',
          },
          {
            from: account,
            to: '0x00005EA00Ac477B1030CE78506496e8C2dE24bf5',
            data: '0x161ac21f000000000000000000000000f34de67c5203420d223173895f873c8c5bb0df140000000000000000000000000000a26b00c1f0df003000390027140000faa71900000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001360c6ebe',
          },
          {
            from: account,
            to: '0x00005EA00Ac477B1030CE78506496e8C2dE24bf5',
            data: '0x161ac21f0000000000000000000000000939b11bcf0e1d85c7a461454b4edd96dec3581f0000000000000000000000000000a26b00c1f0df003000390027140000faa71900000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001360c6ebe',
          },
          {
            from: account,
            to: '0x00005EA00Ac477B1030CE78506496e8C2dE24bf5',
            data: '0x161ac21f000000000000000000000000e92eb4e2322faffe5388e6ce63ffa596d4ad09f60000000000000000000000000000a26b00c1f0df003000390027140000faa71900000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001360c6ebe',
          },
        ],
      });
      // 3. send gtag event
      gtagEvent('batchtx_click_mint', { page, wallet_address: account });

      // 4. passing txHash through handleOneClickMinted
      handleOneClickMinted(txHash);
    } catch (error) {
      if (isUserRejectedRequestError(error)) return;
      toast({
        title: 'Unexpected error occurred, try again later.',
        variant: 'subtle',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box backgroundColor="#F9F9F9" borderRadius="12px">
        <Box padding="32px">
          <Text textAlign="center" color="#00325A" fontWeight="500" lineHeight="22px">
            Dive into our exclusive collection – four unique NFTs ready to be minted on OpenSea.
          </Text>
          <Text
            textAlign="center"
            color="#777A7D"
            fontSize={14}
            fontWeight="500"
            mt="12px"
            lineHeight="22px"
          >
            Typically, minting NFTs means navigating through many pages and buttons.
          </Text>

          <Box mx="-12px" mt="20px">
            <Grid
              templateColumns={{ base: 'repeat(2, 1fr)' }}
              gap={{ base: 'space.s', md: 'space.xl' }}
              maxWidth={{ base: '370px' }}
            >
              {nfts.map((nft, index) => (
                <GridItem width="100%" height="100%">
                  <MintCard
                    imgSrc={nft.imgSrc}
                    onClick={() => {
                      gtagEvent('batchtx_click_opensea', { page, nft_id: index + 1 });
                      const newTabInstance = window.open('');
                      newTabInstance.location = nft.url;
                    }}
                    id={index}
                  />
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Text
        width="100%"
        textAlign="center"
        color="font.highlight"
        fontWeight="500"
        mt="20px"
        lineHeight="22px"
      >
        No more hopping between pages. One-Click Mint for 4 NFTs
        <br />– all for free, no gas fees required!
      </Text>

      <ButtonDefault
        isLoading={isLoading}
        mt="16px"
        size="lg"
        fontWeight="500"
        onClick={handleOneClickMint}
      >
        One click mint
      </ButtonDefault>
    </>
  );
};

NFT.propTypes = {
  handleOneClickMinted: PropTypes.func.isRequired,
};

const MintCard = ({ imgSrc, onClick }) => (
  <Box>
    <Image src={imgSrc} borderRadius="12px" />
    <ButtonDefault
      variant="outline"
      size="xs"
      mt="10px"
      onClick={onClick}
      color="font.highlight"
      fontWeight="500"
      border="unset"
    >
      Mint
    </ButtonDefault>
  </Box>
);

MintCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NFT;
