import React, { useState, useContext } from 'react';
import { Flex, Input, Text, Box, Button, Image, useToast, keyframes } from '@chakra-ui/react';
import coinImg from '../../../static/batch-tx/coin.png';
import enterImg from '../../../static/batch-tx/enter.png';
import magnetImg from '../../../static/batch-tx/magnet.png';
import BatchTxContext from '../../context/batchTx';
import gtagEvent from '../../utils/gtag';
import ButtonDefault from '../button';

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0); }
`;

const hoverAnimationCoin = keyframes`
  from { transform: translateY(0); }
  to { transform: translate(15%, 15%); }
`;

const hoverAnimationEnter = keyframes`
  from { transform: translateY(0); }
  to { transform: translate(10%, 35px); }
`;

const isValidEmail = (value) => /\S+@\S+\.\S+/.test(value);

const MAILCHIMP_MAPS = {
  'save-time': '51b3ed014e',
  'save-fee': 'c3ca167d44',
};

const GTAG_MAPS = {
  'save-fee': 'save_fee',
  'save-time': 'save_time',
};

const FirstSubscribe = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const toast = useToast();
  const { page, address } = useContext(BatchTxContext);

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is not validate');
      setEmail(event.target.value);
      return;
    }
    setError(null);
    setEmail(event.target.value);
    gtagEvent('batchtx_fillout_email', {
      page: GTAG_MAPS[page],
      page_section: 1,
      email: event.target.value,
      ...(address && { wallet_address: address }),
    });
  };

  const mailchimpApi = () => {
    const isSubscribed = localStorage.getItem(`isSubscribed-${page}`);

    if (isSubscribed || !isValidEmail(email)) return;
    const url = 'https://mailchimp-subscribe-kx654nxi2q-uc.a.run.app';
    const data = {
      email_address: email,
      list_id: MAILCHIMP_MAPS[page],
    };

    setLoading(true);
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          toast({
            title: 'Thank you for subscribing',
            variant: 'subtle',
            position: 'top',
            isClosable: true,
          });

          localStorage.setItem(`isSubscribed-${page}`, true);
        } else {
          toast({
            title: res.message,
            variant: 'subtle',
            status: 'error',
            position: 'top',
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        toast({
          title: err,
          variant: 'subtle',
          status: 'error',
          position: 'top',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const animationCoin = isHovering
    ? `${hoverAnimationCoin} 0.5s forwards`
    : `${floatAnimation} infinite 3.2s ease-in-out`;
  const animationEnter = isHovering
    ? `${hoverAnimationEnter} 0.5s forwards`
    : `${floatAnimation} infinite 2.3s ease-in-out`;

  return (
    <Box px={{ base: '24px', md: '40px', lg: '280px' }} pt="80px">
      <Flex
        alignItems="center"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'flex-start', lg: 'space-between' }}
        pt="80px"
      >
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'flex-start' }}
          flexWrap="wrap"
          alignItems="center"
          width="100%"
          maxWidth="630px"
          margin="0 auto"
        >
          <Text
            width="100%"
            textAlign={{ base: 'center', md: 'unset' }}
            color="font.highlight"
            fontSize={{ base: '24px', md: '28px' }}
            fontWeight="500"
          >
            Keep up to date with our new feature launches!
          </Text>
          <Text
            width="100%"
            textAlign={{ base: 'center', lg: 'unset' }}
            color="icon.text"
            fontSize="16"
            pt="12px"
          >
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Don't worry, we hate spam too.
          </Text>
          <Input
            height="52px"
            p="16px 20px 16px 20px"
            width={{ base: '100%' }}
            mr="16px"
            mt="16px"
            bg="background.primary"
            borderColor="#CBD5E0"
            borderRadius="140px"
            placeholder="Your email"
            _placeholder={{ color: 'font.secondary' }}
            letterSpacing="0.32"
            type="email"
            onChange={handleChange}
          />
          <Box w="100%">
            {error && (
              <Text mt="10px" align="left" color="font.alert" w="100%">
                {error}
              </Text>
            )}
          </Box>
          <ButtonDefault
            mt="16px"
            width={{ base: '200px' }}
            height="unset"
            padding={{ base: '16px', md: '26px' }}
            fontSize="size.body.2"
            fontWeight="500"
            textAlign="center"
            mx={{ base: 'auto', lg: 'unset' }}
            isLoading={loading}
            onClick={() => {
              mailchimpApi();
              gtagEvent('batchtx_submit_email', {
                page: GTAG_MAPS[page],
                page_section: 1,
                email,
                ...(address && { wallet_address: address }),
              });
            }}
          >
            Get early access
          </ButtonDefault>
        </Flex>
        <Box
          position="relative"
          width={{ base: '310px', md: '498px' }}
          height={{ base: '330px', md: '529px' }}
          mt={{ base: '40px', lg: '0' }}
        >
          <Image
            src={coinImg}
            position="absolute"
            width={{ base: '104px', md: '168px', lg: '168px' }}
            left={{ base: '10px', md: '0' }}
            top={{ base: '80px', md: '100px' }}
            animation={animationCoin}
            transition="transform 0.5s ease-in-out"
          />
          <Image
            src={enterImg}
            position="absolute"
            width={{ base: '104px', md: '168px', lg: '168px' }}
            right={{ base: '100px', md: '150px' }}
            top="0"
            animation={animationEnter}
          />
          <Image
            src={magnetImg}
            position="absolute"
            width={{ base: '255px', md: '409px', lg: '409px' }}
            right="0"
            bottom="0"
            cursor="pointer"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default FirstSubscribe;
