import React from 'react';
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import Logo from '../../logo';
import iconTwitter from '../../../../static/new_images/footer/icon-twitter.png';
import iconDiscord from '../../../../static/new_images/footer/icon-discord.png';
import iconGitHub from '../../../../static/new_images/footer/icon-github.png';
import iconMeium from '../../../../static/new_images/footer/icon-medium.png';
import CustomLink from '../../customLink';
import gtagEvent from '../../../utils/gtag';
import BatchTxContext from '../../../context/batchTx/index';

const UTM_SOURCE_MAPS = {
  'save-time': 'batchtx-save-time',
  'save-fee': 'batchtx-save-fee',
};

const getSocialMedias = (utmSource) => [
  {
    href: `https://twitter.com/BloctoApp?utm_source=${utmSource}&utm_medium=footer`,
    name: 'Twitter',
    icon: iconTwitter,
  },
  {
    href: `http://discord.gg/QRZTr6yHmY?utm_source=${utmSource}&utm_medium=footer`,
    name: 'Discord',
    icon: iconDiscord,
  },
  {
    href: `https://github.com/blocto?utm_source=${utmSource}&utm_medium=footer`,
    name: 'GitHub',
    icon: iconGitHub,
  },
  {
    href: `https://medium.portto.com/?__hstc=15465641.0591edf7e8fdd8a87a78588e6c635244.1698038801908.1701086358403.1701137044554.17&__hssc=15465641.1.1701137044554&__hsfp=3165990977&utm_source=${utmSource}&utm_medium=footer`,
    name: 'Medium',
    icon: iconMeium,
  },
];

const Footer = () => {
  const { page } = React.useContext(BatchTxContext);
  const socialMedias = getSocialMedias(UTM_SOURCE_MAPS[page]);

  return (
    <Box
      as="footer"
      bg="#F7F7F7"
      overflow="hidden"
      pb={{ base: '4px' }}
      borderRadius={{ base: '30px 30px 0 0', md: '60px 60px 0 0' }}
    >
      <Flex
        w="100%"
        wrap="wrap"
        justify={{ base: 'center' }}
        maxWidth={{ md: '1440px' }}
        m={{ md: '0 auto' }}
        p={{ base: '120px 40px 120px 40px', md: '120px 80px 120px 80px', lg: '120px' }}
      >
        <Flex wrap="wrap" w={{ base: '100%' }} h={{ md: '128px' }}>
          <Flex justify="center" w="100%">
            <CustomLink to="/" title="blocto">
              <Logo width={{ base: '165px' }} height={{ base: '56px' }} color="blue" />
            </CustomLink>
            <Text
              alignSelf="center"
              height="40px"
              fontSize={{ base: '22px', md: '24px' }}
              fontWeight="500"
              pt="3"
              pl="3"
              ml="3"
              borderLeft="1px #BFC2C6 solid"
              whiteSpace="nowrap"
            >
              Batch TX
            </Text>
          </Flex>
          <Flex justify="center" mt="32px" width="100%" marginBottom="0" gap="16px">
            {socialMedias.map(({ href, name, icon }) => (
              <Link
                key={name}
                href={href}
                isExternal
                w="40px"
                h="40px"
                onClick={() => {
                  gtagEvent('bloctoweb_click_socialcommunity', {
                    location: 'footer',
                    social_community: name.toLowerCase(),
                    link_url: href,
                    link_domain: new URL(href).host,
                  });
                }}
              >
                <Image alt={name} src={icon} width="36px" height="36px" />
              </Link>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <Box maxWidth="1440px" mx="auto">
        <Text
          fontSize="14px"
          lineHeight="20px"
          py={{ base: '20px', md: '24px' }}
          mx={{ base: '0', md: '64px', lg: '120px' }}
          color="icon.text"
          borderTop="solid 0.5px"
          borderColor="border.primary"
          textAlign={{ base: 'center', md: 'center' }}
        >
          © {dayjs().year()} portto. All rights reserved.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
