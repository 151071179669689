import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text, Tabs, Tab, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import Defi from './saveTime/defi';
import Security from './saveTime/security';
import NFT from './saveTime/nft';
import MintSuccess from './saveTime/mintSuccess';
import ButtonDefault from '../button';
import './features.css';
import gtagEvent from '../../utils/gtag';

const page = 'save_time';

const Features = () => {
  const [txHash, setTxHash] = useState(null);

  const backToNFTTab = () => {
    setTimeout(() => {
      setTxHash(null);
    }, 5000);
  };

  const handleOneClickMinted = (_txHash) => {
    setTxHash(_txHash);
    backToNFTTab();
  };

  return (
    <>
      <Box
        id="how"
        height={{ base: '80px', md: '40px' }}
        bg="#F8FBFF"
        borderRadius={{ base: '60px 60px 0 0' }}
      />
      <Box
        px={{ base: '24px', md: '24px', lg: '120px' }}
        pt={{ base: '0px', md: '80px', lg: '160px' }}
        pb={{ base: '80px', md: '160px' }}
        borderRadius={{ base: '0 0 60px 60px' }}
        bg="#F8FBFF"
      >
        <Flex
          flexDirection={{ base: 'column' }}
          justifyContent="center"
          w="100%"
          maxW="1200px"
          m="0 auto"
          position="relative"
        >
          <Box textAlign="center">
            <Text color="#0075FF" fontSize={{ base: '40px', md: '60px' }} fontWeight="500">
              HOW
            </Text>
            <Text
              color="#00325A"
              fontWeight="400"
              fontSize={{ md: '20px' }}
              mt="24px"
              lineHeight={{ base: '26px', md: '28px' }}
            >
              Innovating with Batch TX for Savings, Efficiency, and Convenience in Every
              Transaction.
            </Text>
          </Box>

          <Box width="100%" maxWidth="800px" m="40px auto 0">
            <Box padding={{ base: '40px 20px', md: '40px' }} bg="white" borderRadius="12px">
              <Box>
                <Text textAlign="center" color="#00325A" fontSize="28px" fontWeight="500">
                  Save time
                </Text>
                <Text textAlign="center" color="#777A7D" mt="12px" lineHeight="22px">
                  Batch TX is the shortcut to time-efficient and convenient transactions.
                </Text>
              </Box>

              <Box>
                <Tabs variant="unstyled" size="lg" align="center" isFitted>
                  <TabList
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap={{ base: 'space.s', md: 'unset' }}
                    maxWidth={{ base: '180px', md: '90%' }}
                    py="32px"
                    mx="auto"
                  >
                    <SaveTimeTab id="nft">NFT</SaveTimeTab>
                    <SaveTimeTab id="security">Security</SaveTimeTab>
                    <SaveTimeTab id="defi">DeFi</SaveTimeTab>
                  </TabList>
                  <TabPanels>
                    <TabPanel padding="unset">
                      {txHash ? (
                        <MintSuccess txHash={txHash} />
                      ) : (
                        <NFT handleOneClickMinted={handleOneClickMinted} />
                      )}
                    </TabPanel>
                    <TabPanel padding="unset">
                      <Security />
                    </TabPanel>
                    <TabPanel padding="unset">
                      <Defi />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

const SaveTimeTab = ({ children, id }) => (
  <Tab
    as="div"
    _focus={{ boxShadow: 'unset' }}
    className="featureTab"
    padding={{ base: 0, md: '12px 16px' }}
  >
    <ButtonDefault
      variant="gray"
      size={{ base: 'lg', md: 'md' }}
      width="100%"
      backgroundColor="#F9F9F9"
      color="font.secondary"
      fontWeight="500"
      onClick={() => {
        gtagEvent('batchtx_click_tab', { page, tab_name: id });
      }}
    >
      {children}
    </ButtonDefault>
  </Tab>
);

SaveTimeTab.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default Features;
