/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      title: {
        display: true,
        text: 'Total Gas Usage',
      },
    },
    x: {
      title: {
        display: true,
        text: 'Number of Transactions',
      },
    },
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: [
    {
      legend: {
        position: 'top',
      },
    },
    {
      afterDraw: (chart) => {
        if (chart.tooltip?._active?.length) {
          const { x } = chart.tooltip._active[0].element;
          const yAxis = chart.scales.y;
          const { ctx } = chart;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, yAxis.top);
          ctx.lineTo(x, yAxis.bottom);
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ],
};

const BEVChart = ({ data }) => {
  const lineData = {
    labels: data.label,
    datasets: [
      {
        label: 'Your Address',
        data: data.eoaData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.1,
      },
      {
        label: 'Blocto Batch TX',
        data: data.bevData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.1,
      },
    ],
  };

  return <Line options={options} data={lineData} />;
};

BEVChart.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.arrayOf(PropTypes.number),
    bevData: PropTypes.arrayOf(PropTypes.string),
    eoaData: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default BEVChart;
