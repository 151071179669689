/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import {
  Grid,
  Image,
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import BEVChart from './bevchart';
import ButtonDefault from '../button';
import { getSaveFeeResult } from '../../utils/batchTx/saveFee';
import noResultImg from '../../../static/batch-tx/no_result.png';
import gtagEvent from '../../utils/gtag';
import BatchTxContext from '../../context/batchTx';

const DEFAULT_SELECTED_DAY = '1d';
const page = 'save_fee';
const isValidAddress = (value) => /^0x[a-fA-F0-9]{40}$/g.test(value);
const PLACEHOLDER_ADDRESS = '0x284c5Eeba11Fd1aAFc011e7D78e90e7BA703d285';

const Features = () => {
  const [input, setInput] = useState('');
  const [error, setError] = useState(null);
  const [isClicked, SetIsClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalTxFees, setTotalTxFees] = useState(0);
  const [totalTxFeesInUSD, setTotalTxFeesInUSD] = useState(0);
  const [data, setData] = useState({});

  const { setAddress } = useContext(BatchTxContext);
  const toast = useToast();

  const { value, getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: 'days',
  });

  const group = getRootProps();

  const handleInputChange = (e) => {
    if (!isValidAddress(e.target.value)) {
      setError('Address is not validate');
      setInput(e.target.value);
      return;
    }
    setError(null);
    setInput(e.target.value);
    gtagEvent('batchtx_fillout_address', {
      page,
      wallet_address:
        e.target.value.trim() === PLACEHOLDER_ADDRESS ? 'default' : e.target.value.trim(),
    });
  };

  const handleCheck = async () => {
    let address = input.trim();
    if (address === '') address = PLACEHOLDER_ADDRESS;
    if (!/^0x[a-fA-F0-9]{40}$/g.test(address)) return;
    gtagEvent('batchtx_submit_address', {
      page,
      wallet_address: address === PLACEHOLDER_ADDRESS ? 'default' : address,
    });
    try {
      setIsLoading(true);
      SetIsClicked(true);
      setAddress(address === PLACEHOLDER_ADDRESS ? 'default' : address);

      const feeResult = await getSaveFeeResult(address);
      if (Object.keys(feeResult.bevChartData).length === 0) {
        toast({
          title: 'Transactions not found',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      }

      setTotalTxFees(feeResult.sumOfTxsTxFeeInEther);
      setTotalTxFeesInUSD(feeResult.totalTxFeesInUSD);
      setData(feeResult.bevChartData);
      setValue(
        DEFAULT_SELECTED_DAY in feeResult.bevChartData // if 7d data exists, set 7d as default
          ? DEFAULT_SELECTED_DAY
          : Object.keys(feeResult.bevChartData)[0] // else set the first day as default
      );
    } catch (e) {
      toast({
        title: 'Unexpected error occurred, try again later.',
        variant: 'subtle',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        id="how"
        height={{ base: '80px', md: '40px' }}
        bg="#F8FBFF"
        borderRadius={{ base: '60px 60px 0 0' }}
      />
      <Box
        px={{ base: '24px', md: '24px', lg: '120px' }}
        pt={{ base: '0px', md: '80px', lg: '160px' }}
        pb={{ base: '80px', md: '160px' }}
        borderRadius={{ base: '0 0 60px 60px' }}
        bg="#F8FBFF"
      >
        <Flex
          flexDirection={{ base: 'column' }}
          justifyContent="center"
          w="100%"
          maxW="1200px"
          m="0 auto"
          position="relative"
        >
          <Box textAlign="center">
            <Text color="#0075FF" fontSize={{ base: '40px', md: '60px' }} fontWeight="500">
              HOW
            </Text>
            <Text
              color="#00325A"
              fontWeight="400"
              fontSize={{ md: '20px' }}
              mt="24px"
              lineHeight={{ base: '26px', md: '28px' }}
            >
              Innovating with Batch TX for Savings, Efficiency, and Convenience in Every
              Transaction.
            </Text>
          </Box>

          <Box width="100%" maxWidth="800px" m="40px auto 0">
            <Box padding={{ base: '40px 20px', md: '40px' }} bg="white" borderRadius="12px">
              <Box>
                <Text textAlign="center" color="#00325A" fontSize="28px" fontWeight="500">
                  Save fee
                </Text>
                <Text textAlign="center" color="#777A7D" mt="12px" lineHeight="22px" mb="32px">
                  Minimize transaction costs and enjoy saving gas fees with Batch TX!
                </Text>
              </Box>

              <Box backgroundColor="#F9F9F9" borderRadius="12px">
                <Box padding="24px">
                  <FormControl>
                    <FormLabel mb="12px">Your Ethereum Address</FormLabel>
                    <Input
                      value={input}
                      onChange={handleInputChange}
                      bg="background.primary"
                      border="none"
                      padding="16px 20px"
                      placeholder={PLACEHOLDER_ADDRESS}
                      _placeholder={{ color: 'font.secondary' }}
                    />
                    <Box w="100%">
                      {error && (
                        <Text mt="10px" align="start" color="font.alert" w="100%">
                          {error}
                        </Text>
                      )}
                    </Box>
                    <ButtonDefault mt="12px" size="md" onClick={handleCheck} isLoading={isLoading}>
                      Check
                    </ButtonDefault>
                  </FormControl>

                  <Box mt="32px">
                    <Text color="#00325A" fontWeight="500" lineHeight="22px" mb="12px">
                      Past Total Transaction Fees
                    </Text>

                    <Box
                      textAlign="right"
                      padding="16px"
                      backgroundColor="#EEF1F5"
                      borderRadius="12px"
                    >
                      <Text color="#00325A" fontSize="18px" fontWeight="500">
                        {totalTxFees} ETH
                      </Text>
                      <Text as="span" color="#777A7D" fontSize="14px" fontWeight="500">
                        ≈ {totalTxFeesInUSD} USD
                      </Text>
                    </Box>
                  </Box>

                  <Box mt="32px">
                    <Text color="#00325A" fontWeight="500" lineHeight="22px">
                      Explore the cost-effectiveness of Blocto Batch TX and compare its potential
                      savings to EOA wallet.
                    </Text>

                    {data && (
                      <Grid
                        {...group}
                        my="20px"
                        width="100%"
                        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                        gap={{ base: 'space.s', md: 'space.xl', lg: 'space.m' }}
                      >
                        {Object.keys(data).map((day) => {
                          const radio = getRadioProps({ value: day });
                          return (
                            <DaysButton
                              key={day}
                              {...radio}
                              onClick={() => {
                                setValue(day);
                                gtagEvent('batchtx_click_tab', { page, tab_name: day });
                              }}
                            >
                              {day}
                            </DaysButton>
                          );
                        })}
                      </Grid>
                    )}
                  </Box>

                  <Box>
                    {isClicked ? (
                      data[value] && (
                        <Box
                          backgroundColor="white"
                          borderRadius="12px"
                          padding="16px"
                          height={{ base: '400px' }}
                        >
                          <BEVChart data={data[value]} />
                        </Box>
                      )
                    ) : (
                      <Box
                        backgroundColor="white"
                        borderRadius="12px"
                        padding="16px"
                        height={{ base: '400px', md: '352px' }}
                      >
                        <Flex
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          mx="auto"
                          h="100%"
                        >
                          <Image width="100px" src={noResultImg} />
                          <Text
                            textAlign="center"
                            color="#BFC2C6"
                            fontSize="14px"
                            fontWeight="400"
                            mt="space.2xs"
                          >
                            Enter your Ethereum address to generate a report of gas fee savings.
                          </Text>
                        </Flex>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

const DaysButton = ({ onClick, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <>
      <input {...input} />
      <ButtonDefault
        {...checkbox}
        width="100%"
        variant="outline"
        fontWeight="500"
        color="font.secondary"
        borderColor="transparent"
        borderWidth="1.5px"
        style={{ marginInlineStart: '0px' }}
        _hover={{
          borderColor: 'font.highlight',
          borderWidth: '1.5px',
          color: 'font.highlight',
        }}
        _checked={{
          borderColor: 'font.highlight',
          borderWidth: '1.5px',
          color: 'font.highlight',
        }}
        onClick={onClick}
      >
        {/* eslint-disable-next-line react/destructuring-assignment, react/prop-types */}
        {props.children}
      </ButtonDefault>
    </>
  );
};

export default Features;
