import BloctoSDK from '@blocto/sdk';

const rpc = process.env.GATSBY_POLYGON_RPC_ENDPOINT;

const bloctoSDK = new BloctoSDK({
  ethereum: {
    chainId: 137,
    ...(rpc && { rpc }),
  },
});

export default bloctoSDK;
