import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import CustomLink from '../../customLink';
import Logo from '../../logo';

const Header = () => (
    <Flex
      as="nav"
      alignItems="center"
      justifyContent="space-between"
      width={{ base: 'calc(100% - 48px)', md: 'calc(100% - 128px)', lg: '85%' }}
      maxWidth="1200px"
      px={{ lg: 'space.3xl' }}
      margin="0 auto"
      borderRadius="100px"
      position="fixed"
      top={{ base: '24px', lg: '40px' }}
      left="0"
      right="0"
      zIndex={100}
      _after={{
        display: 'block',
        content: "''",
        width: '100%',
        height: {
          base: '62px',
          md: '64px',
          lg: '72px',
        },
        bg: { lg: 'rgba(255, 255, 255, 0.70)' },
        backdropFilter: { lg: 'blur(20px)' },
        boxShadow: { lg: '0px 1px 15px 0px rgba(0, 0, 0, 0.05)' },
        position: 'absolute',
        left: 0,
        zIndex: -10,
        borderRadius: '100px',
      }}
    >
      <Flex
        alignItems="center"
        justifyContent={{ base: 'flex-start', lg: 'inherit' }}
        width={{ base: '100%', lg: 'auto' }}
        height={{
          base: '62px',
          md: '64px',
          lg: '72px',
        }}
        px={{ base: 'space.m', lg: '0' }}
        borderRadius="100px"
        bg={{ base: 'rgba(255, 255, 255, 0.70)', lg: 'transparent' }}
        backdropFilter={{ base: 'blur(20px)', lg: 'none' }}
        boxShadow={{ base: '0px 1px 15px 0px rgba(0, 0, 0, 0.05)', lg: 'none' }}
      >
        <CustomLink
          to="/"
          title="blocto"
          flex="0 0 auto"
          gtag={{ event: 'bloctoweb_click_blocto', properties: { location: 'header' } }}
        >
          <Logo color="blue" />
        </CustomLink>
        <Text fontWeight="500" pt="1" pl="3" ml="3" borderLeft="1px #BFC2C6 solid" >Batch TX</Text>
      </Flex>
    </Flex>
  );

export default Header;
